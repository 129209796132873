function ImageModal({ isOpen, onClose, imageSrc, onSelection }) {
  if (!isOpen) return null;

  const handleImageSelection = () => {
    onSelection('image');
    onClose();
  };

  const handleGiftSelection = () => {
    onSelection('gift');
    onClose();
  };

  return (
    <>
      <div style={overlayStyle} onClick={onClose}></div>
      <div style={modalStyle}>
        <button style={closeButtonStyle} onClick={onClose}>×</button>
        <img
          src={imageSrc}
          alt="Advent Calendar Large"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            borderRadius: '15px'
          }}
        />
        <div style={buttonContainerStyle}>
          <button style={modalButtonStyle} onClick={handleImageSelection}>
            Ich möchte das Bild!
          </button>
          <button style={modalButtonStyle} onClick={handleGiftSelection}>
            Ich möchte ein Geschenk!
          </button>
        </div>
      </div>
    </>
  );
}

export default ImageModal;

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: '20px',
};

const modalButtonStyle = {
  padding: '10px 15px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '14px',
};

const modalStyle = {
  minWidth: '95vw', // Mindestbreite des Modals
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  //backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  overflow: 'hidden',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: '#ff5f5f',
  color: '#fff',
  border: 'none',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  cursor: 'pointer',
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
