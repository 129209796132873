import React, { useState, useEffect } from 'react';
import CalendarMain from './components/CalendarMain';
import LoginModal from './components/LoginModal';
import Footer from './components/Footer';
import HelpModal from './components/HelpModal'; // Neue modale Komponente
import "./components/app.css";
import anleitungicon from './components/images/fragezeichen.png';

function App() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false); // Zustand für das Hilfe-Modal
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
  };

  return (
    <div>
      <header className="header">
        <div className="header-left">
          <img
            src={anleitungicon}
            alt="Hilfe"
            className="help-icon"
            onClick={() => setIsHelpModalOpen(true)}
          />
        </div>
        <h1 className="header-title">Hannahs Adventskalender</h1>
        <div className="header-actions">
          {isLoggedIn ? (
            <button className="button" onClick={handleLogout}>Logout</button>
          ) : (
            <button className="button" onClick={() => setIsLoginModalOpen(true)}>Login</button>
          )}
        </div>
      </header>

      <main className="main">
        {isLoggedIn ? (
          <CalendarMain />
        ) : (
          <p style={{ color: 'white' }}>Bitte logge dich ein, um den Kalender zu sehen.</p>
        )}
      </main>

      {isLoginModalOpen && (
        <LoginModal
          onClose={() => setIsLoginModalOpen(false)}
          onLoginSuccess={() => setIsLoggedIn(true)}
        />
      )}

      {isHelpModalOpen && (
        <HelpModal onClose={() => setIsHelpModalOpen(false)} />
      )}

      <Footer />
    </div>
  );
}

export default App;
