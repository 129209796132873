import React from 'react';

function HelpModal({ onClose }) {
  return (
    <>
      <div style={overlayStyle} onClick={onClose}></div>
      <div style={modalStyle}>
        <button style={closeButtonStyle} onClick={onClose}>×</button>
        <div>
          <h2>Hilfe</h2>
          <p>Dies ist Hannahs Adventskalender. Öffne jeden Tag ein neues Türchen und freue dich die Überraschungen!</p>
          <p> Damit du nicht "leer" ausgehst darfst du dich jeden Tag aufs neue entscheiden. Möchtest du am Ende das Bild bekommen, oder gefällt dir das Bild nicht und du möchtest lieber ein zusätzliches Geschenk? Du hast die Wahl!</p>
          <p>Wähle weise. Du musst dich für mindestens 12 Bilder entscheiden!</p>
          <p>Viel Spaß beim öffnen. Dein Toni</p>
          <p>Geheimtipp: Du kannst deine Wahl notfalls ändern</p>
           
        </div>
      </div>
    </>
  );
}

export default HelpModal;

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '90%',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: '#ff5f5f',
  color: '#fff',
  border: 'none',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  cursor: 'pointer',
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
