import React, { useEffect } from 'react';
import Tile from './Tile';
import Background from './images/Background.jpg'; // Importiere das Bild
import Schneeflocke from './images/Schneeflocke.png';

function CalenderMain() {
  const tiles = [
    { number: '01', orientation: 'portrait'},
    { number: '02', orientation: 'portrait' }, 
    { number: '03', orientation: 'portrait' },
    { number: '04', orientation: 'portrait'},
    { number: '05', orientation: 'portrait'},
    { number: '06', orientation: 'portrait'},
    { number: '07', orientation: 'portrait'},
    { number: '08', orientation: 'portrait'},
    { number: '09', orientation: 'portrait'},
    { number: '10', orientation: 'portrait'},
    { number: '11', orientation: 'portrait'},
    { number: '12', orientation: 'portrait'},
    { number: '13', orientation: 'portrait'},
    { number: '14', orientation: 'portrait'},
    { number: '15', orientation: 'portrait'},
    { number: '16', orientation: 'portrait'},
    { number: '17', orientation: 'portrait'},
    { number: '18', orientation: 'portrait'},
    { number: '19', orientation: 'portrait'},
    { number: '20', orientation: 'portrait'},
    { number: '21', orientation: 'portrait'},
    { number: '22', orientation: 'portrait'},
    { number: '23', orientation: 'portrait'},
    { number: '24', orientation: 'portrait'},
  ];

  useEffect(() => {
    // Dynamisch Schneeflocken erstellen
    const snowContainer = document.createElement('div');
    snowContainer.id = 'snow-container';
    document.body.appendChild(snowContainer);

    for (let i = 0; i < 50; i++) {
      const snowflake = document.createElement('img');
      snowflake.src = Schneeflocke;
      snowflake.className = 'snowflake';
      snowflake.style.left = `${Math.random() * 100}vw`;
      snowflake.style.animationDuration = `${Math.random() * 5 + 5}s`; // 5-10 Sekunden
      snowflake.style.animationDelay = `${Math.random() * 5}s`; // Verzögerung
      snowContainer.appendChild(snowflake);
    }

    return () => {
      // Entferne Schneeflocken beim Verlassen der Komponente
      snowContainer.remove();
    };
  }, []);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // Immer 3 Spalten
        gap: '10px',
        paddingTop: '20px', // Nur oben 20px Padding
        width: '100%', // Verhindert seitliches Scrollen
        boxSizing: 'border-box', // Bezieht das Padding in die Gesamtbreite ein
        minHeight: '100vh', // Stellt sicher, dass der Inhalt mindestens die Ansichtshöhe abdeckt
      }}
    >
      {tiles.map((tile, index) => (
        <Tile
          key={index}
          number={tile.number}
          orientation={tile.orientation}
        />
      ))}
    </div>
  );
}

export default CalenderMain;

// Hintergrund global für die Seite setzen
document.body.style.backgroundImage = `url(${Background})`;
document.body.style.backgroundSize = 'cover';
document.body.style.backgroundRepeat = 'no-repeat';
document.body.style.backgroundAttachment = 'fixed';
document.body.style.backgroundPosition = 'center';

// CSS für Schneeflocken
const style = document.createElement('style');
style.textContent = `
  #snow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: -1; /* Hintergrund-Ebene */
  }
  
  .snowflake {
    position: absolute;
    top: -10%;
    width: 30px;
    height: 30px;
    opacity: 0.8;
    animation: fall linear infinite;
  }
  
  @keyframes fall {
    to {
      transform: translateY(110vh);
    }
  }
`;
document.head.appendChild(style);