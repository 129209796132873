import React, { useState } from "react";

function LoginModal({ onClose, onLoginSuccess }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(apiUrl + "/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json(); // Parse JSON response
        const { accessToken } = data; // Extrahiere accessToken

        localStorage.setItem("authToken", accessToken); // Speichere das Token im Local Storage
        onLoginSuccess(); // Informiere die Hauptkomponente über den Erfolg
        onClose(); // Schließe das Modal
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Login fehlgeschlagen");
      }
    } catch (err) {
      setError("Netzwerkfehler. Bitte erneut versuchen.");
    }
  };

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <h2 style={modalStyles.heading}>Login</h2>
        {error && <p style={modalStyles.error}>{error}</p>}
        <form onSubmit={handleLogin} style={modalStyles.form}>
          <div style={modalStyles.inputGroup}>
            <label style={modalStyles.label}>
              Benutzername:
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                style={modalStyles.input}
              />
            </label>
          </div>
          <div style={modalStyles.inputGroup}>
            <label style={modalStyles.label}>
              Passwort:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={modalStyles.input}
              />
            </label>
          </div>
          <div style={modalStyles.buttonGroup}>
            <button type="submit" style={modalStyles.primaryButton}>
              Login
            </button>
            <button
              type="button"
              onClick={onClose}
              style={modalStyles.secondaryButton}
            >
              Abbrechen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "12px",
    width: "320px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  heading: {
    margin: "0 0 20px",
    fontSize: "1.5rem",
    color: "#2c3e50",
  },
  error: {
    color: "red",
    margin: "10px 0",
    fontSize: "0.9rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputGroup: {
    marginBottom: "15px",
    width: "100%",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "0.9rem",
    color: "#34495e",
    textAlign: "left",
  },
  input: {
    width: "90%",
    padding: "10px",
    borderRadius: "6px",
    border: "1px solid #bdc3c7",
    fontSize: "1rem",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  primaryButton: {
    padding: "10px 20px",
    backgroundColor: "#27ae60",
    color: "white",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  secondaryButton: {
    padding: "10px 20px",
    backgroundColor: "#e74c3c",
    color: "white",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "1rem",
  },
};

export default LoginModal;
