// Footer.js
import React, { useEffect, useState } from 'react';
import Rentier from './images/Rentier.jpg'; // Importiere das Bild

const Footer = () => {
  const footerStyle = {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
    fontSize: '1.2em',
    overflow: 'hidden',
    pointerEvents: 'none', // Klicks gehen durch den Footer hindurch
  };
  

  const reindeerStyle = {
    position: 'absolute',
    bottom: '15%',
    width: '15%',
    animation: 'reindeer-move 20s linear infinite',
  };

  const [reindeers, setReindeers] = useState([]);

  useEffect(() => {
    const reindeerInterval = setInterval(() => {
      const id = Date.now(); // Eindeutige ID für das Rentier
      setReindeers((prevReindeers) => [
        ...prevReindeers,
        {
          id,
          left: `${Math.random() * 100}%`,
        },
      ]);
      setTimeout(() => {
        setReindeers((prevReindeers) =>
          prevReindeers.filter((reindeer) => reindeer.id !== id)
        );
      }, 20000); // Rentier bleibt 10 Sekunden sichtbar
    }, 5000); // Rentier wird alle 5 Sekunden erstellt
  
    return () => {
      clearInterval(reindeerInterval);
    };
  }, []);

  return (
    <footer style={footerStyle}>
      {reindeers.map((reindeer) => (
        <img
          key={reindeer.id}
          src={Rentier}
          alt="Reindeer"
          style={{ ...reindeerStyle, left: reindeer.left }}
        />
      ))}
      <style>
        {`
          @keyframes reindeer-move {
            from {
              transform: translateX(-10vw);
            }
            to {
              transform: translateX(110vw);
            }
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;
