import React, { useState } from 'react';

// Separate component for fetching and displaying the image
function ImageFetcher({ imageName, onDownload, onImageLoaded}) {
  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState('');

  React.useEffect(() => {
    if (!imageName) {
      setImageSrc('');
      setError('');
      return;
    }

    const fetchImage = async () => {
      try {
        setError(''); // Clear any previous errors

        // Hole das Token aus dem Local Storage
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('Unauthorized: No token found. Please log in.');
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(apiUrl+ `/image?name=${imageName}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Token im Header senden
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText || 'Image not found or server error');
        }

        const blob = await response.blob(); // Get the image as a blob
        const url = URL.createObjectURL(blob); // Create a local URL for the blob
        setImageSrc(url); // Set the image source

        // Übergib die URL an die übergeordnete Komponente (z. B. für den Download)
        if (onDownload) {
          onDownload(url);
        }
        // Übergib die URL auch an die `onImageLoaded`-Callback-Funktion
        if (onImageLoaded) {
          onImageLoaded(url);
        }
      } catch (err) {
        setError(err.message);
        setImageSrc(''); // Clear the image source on error
      }
    };

    fetchImage();
  }, [imageName, onDownload, onImageLoaded]); // Re-fetch the image whenever imageName changes

  return (
    <div style={{  }}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {imageSrc && <img src={imageSrc} alt="Loaded" style={{ maxWidth: '100%', borderRadius: '20px' }} />}
    </div>
  );
}

export default ImageFetcher;
