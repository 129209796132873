import React, { useState, useEffect } from 'react';
import ImageFetcher from './ImageFetcher';
import ImageModal from './ImageModal';
import KameraIcon from './images/kamera.png'
import GeschenkIcon from './images/geschenk.png'

function Tile({ number, orientation, customStyle }) {
  const [isFlipped, setIsFlipped] = useState(() => {
    return localStorage.getItem(`tile-${number}`) === 'true';
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(() => {
    return localStorage.getItem(`tile-icon-${number}`) || null;
  });
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const flippedStatus = localStorage.getItem(`tile-${number}`);
    if (flippedStatus === 'true') {
      setIsFlipped(true);
    }
  }, [number]);

  const handleClick = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    if (number <= currentDay) {
      if (!isFlipped) {
        setIsFlipped(true);
        setIsModalOpen(true);
        localStorage.setItem(`tile-${number}`, 'true');
      } else {
        setIsModalOpen(true);
      }
    } else {
      alert(`Türchen ${number} kann erst am ${number}. Dezember geöffnet werden!`);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelection = (selection) => {
    setSelectedIcon(selection);
    localStorage.setItem(`tile-icon-${number}`, selection);
  };

  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative', // Damit Inhalte relativ zur Kachel positioniert werden können
  };

  const iconContainerStyle = {
    position: 'absolute', // Positioniert Icons über dem Bild
    bottom: '10px', // Abstand vom unteren Rand
    right: '10px', // Abstand vom rechten Rand
    zIndex: 10, // Sicherstellen, dass Icons vor dem Bild liegen
    display: 'flex',
    gap: '5px', // Platz zwischen mehreren Icons
  };

  const iconStyle = {
    width: '30px',
    height: '30px',
    objectFit: 'contain',
  };

  return (
    <>
      <div
        style={{
          ...baseStyle,
          ...customStyle,
          width: orientation === 'portrait' ? '120px' : '150px',
          height: orientation === 'portrait' ? '150px' : '120px',
        }}
        onClick={handleClick}
      >
        <div>
          {isFlipped ? (
            <>
              <ImageFetcher
                imageName={`IMG_${String(number).padStart(2, '0')}`}
                onImageLoaded={setImageUrl}
              />
              <div style={iconContainerStyle}>
                {selectedIcon === 'image' && (
                  <img
                    src={KameraIcon}
                    alt="Bild Icon"
                    style={iconStyle}
                  />
                )}
                {selectedIcon === 'gift' && (
                  <img
                    src={GeschenkIcon}
                    alt="Geschenk Icon"
                    style={iconStyle}
                  />
                )}
              </div>
            </>
          ) : (
            <ImageFetcher imageName={`Tile_${String(number).padStart(2, '0')}`} />
          )}
        </div>
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={imageUrl}
        onSelection={handleSelection}
      />
    </>
  );
}

export default Tile;

